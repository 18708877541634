var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.classificationDegree.classificationDegreeImagePath,
            _vm.classificationDegree.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.classificationDegree.classificationDegreeImageIsDefault &&
          _vm.checkPrivilege(_vm.hasClassificationDegreeDeleteImage())},on:{"changeValue":function($event){_vm.classificationDegree.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.classificationDegree.fullCode,"title":_vm.$t('ClassificationDegrees.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.classificationDegree.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-classificationDegreeNameAr"),"errors":_vm.errors_classificationDegreeNameAr,"value":_vm.classificationDegree.classificationDegreeNameAr,"title":_vm.$t('ClassificationDegrees.nameAr'),"imgName":'classificationDegrees.svg'},on:{"changeValue":function($event){_vm.classificationDegree.classificationDegreeNameAr = $event;
            _vm.$v.classificationDegree.classificationDegreeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-classificationDegreeNameEn"),"errors":_vm.errors_classificationDegreeNameEn,"value":_vm.classificationDegree.classificationDegreeNameEn,"title":_vm.$t('ClassificationDegrees.nameEn'),"imgName":'classificationDegrees.svg'},on:{"changeValue":function($event){_vm.classificationDegree.classificationDegreeNameEn = $event;
            _vm.$v.classificationDegree.classificationDegreeNameEn.$touch();}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-6',"id":(_vm.id + "-classificationDegreeGradeFrom"),"value":_vm.classificationDegree.classificationDegreeGradeFrom,"title":_vm.$t('general.degreeFrom'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.classificationDegree.classificationDegreeGradeFrom = $event}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-6',"id":(_vm.id + "-classificationDegreeGradeTo"),"value":_vm.classificationDegree.classificationDegreeGradeTo,"title":_vm.$t('general.degreeTo'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.classificationDegree.classificationDegreeGradeTo = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-classificationDegreeNotes"),"value":_vm.classificationDegree.classificationDegreeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.classificationDegree.classificationDegreeNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }